import { useEffect, useState } from 'react';
import useDebounce from './use-debounce';
import { query as queryAppleMap } from 'utils/apple-maps';
import { createQueryFunction as createQueryGoogleMapsFunction } from 'utils/google-maps';
import { groupBy, uniqBy } from 'lodash';
import { SEARCH_OPTION_AGENTS, SEARCH_OPTION_HOME_APPRAISAL } from 'themes/themeConfig';
import { getSearchPredictions, createSearchPredictionModel } from 'data/search-predictions';
import * as AgentActions from 'utils/agent-endpoint';
import { useUser } from 'contexts';
import { CountryCodeList } from 'types/countries';

import type { SearchOptions } from 'themes/themeConfig';
import type SearchPrediction from 'data/search-predictions';
import type { SearchAgentPrediction } from 'components/suggested-location-dropdown';
import { QuerySettingsType } from 'utils/google-maps/types';
import { getHomeAppraisalPredictions } from 'utils/google-maps/api/placePredictions';

export enum searchProvider {
  GOOGLE, APPLE
}

export default function useLocationSearch(
  queryString: string,
  useNewSearch = false,
  useUsListings = false,
  provider = searchProvider.GOOGLE,
  searchOptions?: SearchOptions
) {
  const [isTyping, setIsTyping] = useState(false);
  const [isLoadingSearchPredictions, setIsLoadingSearchPredictions] = useState(false);
  const [searchPredictions, setSearchPredictions] = useState<SearchPrediction[]>([]);
  const [searchAgentPredictions, setSearchAgentPredictions] = useState<SearchAgentPrediction[]>([]);
  const debouncedSearch = useDebounce(() => search(useUsListings), 400);
  const { siteLocation } = useUser();

  const uniqByDescriptionAndAddLocationId = (locations: SearchPrediction[]) => {
    const resultMap: { [description: string]: SearchPrediction } = {};
    for (const location of locations) {
      const descriptionKey = location.description.toLowerCase();
      if (descriptionKey in resultMap) {
        const existingLocation = resultMap[descriptionKey];
        if (location.source === 'google') {
          existingLocation.locationId = location.id;
          existingLocation.source = location.source;
        }
      } else {
        resultMap[descriptionKey] = { ...location } as SearchPrediction;
      }
    }
    return Object.values(resultMap);
  };

  const modelingCities = (cities: SearchPrediction[]) => cities.map(createSearchPredictionModel);

  const googleQuerySettings: QuerySettingsType = {
    limits: {
      city: 10, neighborhood: 0, street: 10,
    },
  };

  const search = async (useUsListings: boolean) => {
    const performSearch = queryString.length > 2;
    const performReset = queryString.length === 0;
    if (performSearch) {
      setIsLoadingSearchPredictions(true);
      const isAgentSearch = searchOptions === SEARCH_OPTION_AGENTS;
      const isHomeAppraisalSearch = searchOptions === SEARCH_OPTION_HOME_APPRAISAL;
      if (isAgentSearch) {
        const data = await AgentActions.locationSuggestions(queryString, CountryCodeList.CANADA);
        const searchAgentPredictions = data.map((i, index) => ({ identifier: index, label: i, group: 'locations' }));
        setSearchPredictions([]);
        setSearchAgentPredictions(searchAgentPredictions);
      } else {
        let searchPredictions = await getSearchPredictions({ query: queryString.toLowerCase(), country : useUsListings ? '' : 'CA' }, useNewSearch) as SearchPrediction[];
        if (provider === searchProvider.GOOGLE || !areLocationsPresentInResult(searchPredictions)) {
          try {
            let results;
            // If results for home appraisal we need only address not cities
            if (isHomeAppraisalSearch) {
              results = await getHomeAppraisalPredictions(queryString, siteLocation);
            } else if (provider === searchProvider.APPLE) {
              results = await queryAppleMap(queryString);
            } else {
              results = await createQueryGoogleMapsFunction(useUsListings)(queryString, googleQuerySettings);
            }
            // If isHomeAppraisalSearch is true and there are more then 10 results, do not show searchPredictions
            // Show searchPredictions only if there are less then 10 results and no more then 10
            if (isHomeAppraisalSearch) {
              if (results.length > 9) {
                searchPredictions = [];
                results = results.slice(0, 10);
              } else {
                const remainingSpaceForPredictions = 10 - results.length;
                searchPredictions = searchPredictions.slice(0, remainingSpaceForPredictions);
              }
            }

            const predictionsGroupedByGroup = groupBy([... searchPredictions, ...results], 'group');
            const { listings = [], schools = [], buildings = [], locations = []} = predictionsGroupedByGroup;

            const locationsGroupedByLabel = groupBy(locations, 'label');
            const { city = [], neighbourhood = [], street = []} = locationsGroupedByLabel;
            // Remove duplicate cities that could come from the SearchPredictionStore and preserve locationId that comes from Google
            const citiesWithLocationIds = uniqByDescriptionAndAddLocationId(city);
            const uniqCities = modelingCities(citiesWithLocationIds);
            // Remove duplicates that could come from the SearchPredictionStore
            const UNIQUENESS_ATTRIBUTE = 'description';
            const uniqNeighborhoods = uniqBy(neighbourhood, UNIQUENESS_ATTRIBUTE);
            const uniqStreets = uniqBy(street, UNIQUENESS_ATTRIBUTE);

            if (isHomeAppraisalSearch) {
              searchPredictions = [
                ...listings,
                ...uniqCities,
                ...uniqStreets,
              ];
            } else {
              searchPredictions = [
                ...listings,
                ...schools,
                ...buildings,
                ...uniqCities,
                ...uniqNeighborhoods,
                ...uniqStreets,
              ];
            }

            setSearchPredictions(searchPredictions);
            setSearchAgentPredictions([]);
          } catch (err: any) {
            console.error(err);
          }
        }
      }
      setIsTyping(false);
      setIsLoadingSearchPredictions(false);
    } else if (performReset) {
      setIsTyping(false);
      setSearchPredictions([]);
      setSearchAgentPredictions([]);
      setIsLoadingSearchPredictions(false);
    }
  };

  const areLocationsPresentInResult = (searchPredictions: SearchPrediction[]) => {
    return searchPredictions.reduce((areLocationResultsPresent: boolean, prediction: SearchPrediction) => {
      return areLocationResultsPresent = prediction.group === 'locations' ? true : false;
    }, false);
  };

  useEffect(() => {
    debouncedSearch();
    !!queryString.length && setIsTyping(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, searchOptions]);

  return { isLoadingSearchPredictions, searchPredictions, searchAgentPredictions, isTyping };
}