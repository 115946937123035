import { loadScript } from 'utils/load-file';
import {
  AutocompletePredictionRestrictionTypes,
  AutocompletePredictionTypes,
  QueryResultType,
} from '../types';
import { autocompletePredictionToSearchResults } from 'utils/google-maps/searchPredictions';
import { CountryCode, CountryCodeList } from 'types/countries';
import SearchPrediction from 'data/search-predictions';

const PLACE_TYPES_TO_REQUEST: string[] = [
  AutocompletePredictionRestrictionTypes.CITIES,
  AutocompletePredictionRestrictionTypes.REGIONS,
  AutocompletePredictionRestrictionTypes.GEOCODE,
  AutocompletePredictionRestrictionTypes.ADDRESS,
];
const COUNTRY_FILTER: readonly string[] = ['ca', 'us']; // Array of up to five country code strings

const SUPPORTED_PLACE_TYPES: string[] = [
  AutocompletePredictionTypes.NEIGHBORHOOD,
  AutocompletePredictionTypes.GEOCODE,
  AutocompletePredictionTypes.LOCALITY,
  AutocompletePredictionTypes.SUBLOCALITY,
  AutocompletePredictionTypes.SUBLOCALITY_LEVEL_1,
  AutocompletePredictionTypes.ADMINISTRATIVE_AREA_LEVEL_2,
  AutocompletePredictionTypes.ADMINISTRATIVE_AREA_LEVEL_3,
  AutocompletePredictionTypes.ROUTE,
];

const PLACE_TYPE_INDEX = 0;

export async function getAutoCompleteService() {
  const noGoogleMap = !(window as any).google || !(window as any).google.maps;
  if (noGoogleMap) {
    await loadScript({ source: `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''}&libraries=places&callback=initGoogle` });
  }

  if (!google) {
    return undefined;
  }

  return new google.maps.places.AutocompleteService();
}

// Create a new session token.
let sessionToken: google.maps.places.AutocompleteSessionToken | null = null;

export function createAutocompletionRequest(
  input: string,
  type: string = AutocompletePredictionRestrictionTypes.GEOCODE,
  country: readonly string[] = COUNTRY_FILTER
) {
  if (!sessionToken) {
    sessionToken = new google.maps.places.AutocompleteSessionToken();
  }
  const request: google.maps.places.AutocompletionRequest = {
    input,
    componentRestrictions: { country: [... country]},
    types: [type],
    sessionToken,
  };
  return request;
}

export const getHomeAppraisalPredictions = async (
  input: string,
  country: CountryCode = CountryCodeList.CANADA, // Country of origin
  apiKey: string = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''
): Promise<SearchPrediction[]> => {
  if (!(apiKey?.length > 0)) {
    throw new Error('No Google API key was found.');
  }

  const autocompleteService = await getAutoCompleteService();
  // Only get this type to avoid getting too broad results like cities
  const types = ['street_address', 'street_number', 'route'];
  const allPredictions = [];

  if (autocompleteService) {
    for (const type of types) {
      const request = createAutocompletionRequest(input, type, COUNTRY_FILTER);
      const response = await autocompleteService.getPlacePredictions(request);
      allPredictions.push(...response.predictions);
    }

    const uniquePredictions = Array.from(new Map(allPredictions.map(prediction => [prediction.place_id, prediction])).values());

    // Show the country of origin first
    const countryName = country === CountryCodeList.CANADA ? 'Canada' : 'USA';
    const sortedPredictions = uniquePredictions.sort((a, b) => {
      const isACountryOfOrigin = a.terms.some(term => term.value === countryName);
      const isBCountryOfOrigin = b.terms.some(term => term.value === countryName);
      return isACountryOfOrigin === isBCountryOfOrigin ? 0 : isACountryOfOrigin ? -1 : 1;
    });

    // Parse results to make it works with advanced search dropdown component
    const parsedPredictions = autocompletePredictionToSearchResults(sortedPredictions) as SearchPrediction[];

    return parsedPredictions;
  } else return [];
};

export default async function query(
  input: string,
  country: readonly string[] = COUNTRY_FILTER,
  apiKey: string = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''
) {
  if (!(apiKey?.length > 0)) {
    throw new Error('No Google API key was found.');
  }

  const autocompleteService = await getAutoCompleteService();

  if (autocompleteService) {
    const cityPredictions: google.maps.places.AutocompletePrediction[] = [];
    const nbhdPredictions: google.maps.places.AutocompletePrediction[] = [];
    const streetPredictions: google.maps.places.AutocompletePrediction[] = [];
    const uniquePlaceId = new Set<string>();

    for (let index = 0; index < PLACE_TYPES_TO_REQUEST.length; index++) {
      const type = PLACE_TYPES_TO_REQUEST[index];
      const request = createAutocompletionRequest(input, type, country);
      const response = await autocompleteService.getPlacePredictions(request);

      if (response?.predictions) {
        response?.predictions.reduce(function (
          uniquePlaceId: Set<string>,
          prediction: google.maps.places.AutocompletePrediction
        ) {
          if (
            SUPPORTED_PLACE_TYPES.includes(
              prediction.types[PLACE_TYPE_INDEX]
            ) &&
            !uniquePlaceId.has(prediction.place_id)
          ) {
            switch (prediction.types[PLACE_TYPE_INDEX]) {
            case AutocompletePredictionTypes.SUBLOCALITY:
            case AutocompletePredictionTypes.NEIGHBORHOOD:
              nbhdPredictions.push(prediction);
              break;
            case AutocompletePredictionTypes.ROUTE:
              streetPredictions.push(prediction);
              break;
            default:
              cityPredictions.push(prediction);
            }

            uniquePlaceId.add(prediction.place_id);
          }
          return uniquePlaceId;
        },
        uniquePlaceId);
      }
    }

    const result: QueryResultType = {
      city: cityPredictions,
      neighborhood: nbhdPredictions,
      street: streetPredictions,
    };

    return result;
  }

  throw new Error('AutocompleteService could not be created.');
}